import  React, { Fragment } from "react"
import PropTypes from "prop-types"
import { graphql, useStaticQuery, Link } from "gatsby"
// import { Helmet } from "react-helmet"

import { Popover, Transition } from '@headlessui/react'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/react/outline'
import { ChevronDownIcon } from '@heroicons/react/solid'
 
import Logo from "../../static/img/elite-Logo-dark.svg"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Header = () => {

  const data = useStaticQuery(graphql`
    {
      prismicNavigationMenu {
        id
        data {
          navigation_name
          body {
            primary {
              nav_title
              nav_url {
                slug
                uid
              }
              sub_menu
            }
            items {
              sub_nav_item_title
              sub_nav_item_url {
                slug
                uid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <Popover className="relative bg-white">
      {({ open }) => (
        <>
          <div className="mx-auto px-4 sm:px-6">
            <div className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
              <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/"><img className="h-8 w-auto sm:h-10" src={Logo} alt="Elite Airborne" /></Link>
              </div>

              {/* /////////// */}
              {/* Mobile Menu */}
              {/* /////////// */}

              <div className="-mr-2 -my-2 md:hidden">
                <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              
              {/* ///////////////// */}
              {/* End - Mobile Menu */}
              {/* ///////////////// */}

              <Popover.Group as="nav" className="hidden md:flex space-x-10">
                {data.prismicNavigationMenu.data.body.map( nav => (
                  <>
                    {!nav.primary.sub_menu &&
                      <Link id={nav.primary.nav_url.uid} to={nav.primary.nav_url.uid==="homepage"?"/":"../"+nav.primary.nav_url.uid} className="text-base font-medium text-gray-500 hover:text-gray-900">
                        {nav.primary.nav_title}
                      </Link>
                    }
                    {nav.primary.sub_menu &&
                      <Popover id={nav.primary.nav_url.uid} className="relative">
                        {({ open }) => (
                          <>
                            <Popover.Button
                              className={classNames(
                                open ? 'text-gray-900' : 'text-gray-500',
                                'group bg-white rounded-md inline-flex items-center text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                              )}
                            >
                              <span>{ nav.primary.nav_title }</span>
                              <ChevronDownIcon
                                className={classNames(
                                  open ? 'text-gray-600' : 'text-gray-400',
                                  'ml-2 h-5 w-5 group-hover:text-gray-500'
                                )}
                                aria-hidden="true"
                              />
                            </Popover.Button>
      
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-200"
                              enterFrom="opacity-0 translate-y-1"
                              enterTo="opacity-100 translate-y-0"
                              leave="transition ease-in duration-150"
                              leaveFrom="opacity-100 translate-y-0"
                              leaveTo="opacity-0 translate-y-1"
                            >
                              <Popover.Panel
                                static
                                className="absolute z-10 -ml-4 mt-3 transform px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2"
                              >
                                <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                                  <div className="relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8">
                                    {nav.items.map((item) => (
                                      <Link
                                        key={item.sub_nav_item_url.uid}
                                        to={ "../" + item.sub_nav_item_url.uid}
                                        className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                                      >
                                        {/* <item.icon className="flex-shrink-0 h-6 w-6 text-indigo-600" aria-hidden="true" /> */}
                                        <div className="ml-4">
                                          <p className="text-base font-medium text-gray-900">{item.sub_nav_item_title}</p>
                                          <p className="mt-1 text-sm text-gray-500">Something about the service</p>
                                        </div>
                                      </Link>
                                    ))}
                                  </div>
                                  {/* <div className="px-5 py-5 bg-gray-50 space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                                    {callsToAction.map((item) => (
                                      <div key={item.name} className="flow-root">
                                        <a
                                          href={item.href}
                                          className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-gray-900 hover:bg-gray-100"
                                        >
                                          <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                          <span className="ml-3">{item.name}</span>
                                        </a>
                                      </div>
                                    ))}
                                  </div> */}
                                </div>
                              </Popover.Panel>
                            </Transition>
                          </>
                        )}
                      </Popover>
                    }
                  </>
                ))}
              </Popover.Group>
              <div className="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <a href="#" className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                  Sign in
                </a>
                <a
                  href="#"
                  className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Sign up
                </a>
              </div>
            </div>
          </div>

          {/* ///////////////// */}
          {/* Mobile Menu Panel */}
          {/* ///////////////// */}

          <Transition
            show={open}
            as={Fragment}
            enter="duration-200 ease-out"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="duration-100 ease-in"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Popover.Panel
              focus
              static
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden z-50"
            >
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                <div className="pt-5 pb-6 px-5">
                  <div className="flex items-center justify-between">
                    <div>
                      <Link to="/"><img className="h-8 w-auto" src={Logo} alt="Elite Airborne" /></Link>
                    </div>
                    <div className="-mr-2">
                      <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <XIcon className="h-6 w-6" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                  </div>
                  <div className="mt-6">
                    <nav className="grid gap-y-8">
                      {data.prismicNavigationMenu.data.body.map( nav => (
                        <>
                          {!nav.primary.sub_menu &&
                            <Link
                              key={nav.primary.nav_url.uid}
                              to={nav.primary.nav_url.uid==="homepage"?"/":nav.primary.nav_url.uid} 
                              className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                            >
                              {nav.primary.nav_title}
                            </Link>
                          }
                        </>
                      ))}
                    </nav>
                  </div>
                </div>
                <div className="py-6 px-5 space-y-6">
                  <div>
                    {data.prismicNavigationMenu.data.body.map( nav => (
                      <div key={ nav.primary.nav_title} className="grid grid-cols-2 gap-y-4 gap-x-8">
                        {nav.primary.sub_menu &&
                          <>
                            {nav.items.map( item => (
                              <Link key={item.sub_nav_item_url.uid} to={item.sub_nav_item_url.uid} className="text-base font-medium text-gray-900 hover:text-gray-700">
                                {item.sub_nav_item_title}
                              </Link>
                            ))}
                          </>
                        }
                      </div>
                    ))}
                  </div>
                  <div>
                    <a
                      href="#"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Sign up
                    </a>
                    <p className="mt-6 text-center text-base font-medium text-gray-500">
                      Existing customer?{' '}
                      <a href="#" className="text-indigo-600 hover:text-indigo-500">
                        Sign in
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header