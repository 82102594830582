import  React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const Footer = () => {

    const data = useStaticQuery(graphql`
      {
        prismicNavigationMenu {
          id
          data {
            navigation_name
            body {
              primary {
                nav_title
                nav_url {
                  slug
                  uid
                }
                sub_menu
              }
              items {
                sub_nav_item_title
                sub_nav_item_url {
                  slug
                  uid
                }
              }
            }
          }
        }
      }
    `)
  
    return (
        <div className="bg-elite-dark w-full py-24">

        </div>
    )
}
  
  export default Footer